import {useState, useEffect} from 'react';
import TooltipComponent from "../atoms/TooltipComponent";
import SvgCalendar from "../icons/SvgCalendar";
import SvgClock from "../icons/SvgClock";
import {DateTime} from 'luxon';
import {dateOrdinal} from '../../utils/helpers';
import ShowMoreText from 'react-show-more-text'
import ImageLightbox from "../atoms/ImageLightbox";
import SvgLocation from "../icons/SvgLocation";
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../../utils/consts";

function EventPageHeaderDetails({event, isGuest, hideLocation}) {
    const [isMultiDayEvent, setIsMultiDayEvent] = useState(false);

    useEffect(() => {
        setIsMultiDayEvent(DateTime.fromISO(event.start_timestamp).toFormat("yyyy-MM-dd") < DateTime.fromISO(event.end_timestamp).toFormat("yyyy-MM-dd"))
    }, [event])

    const handleCopy = async () => {
        const addressParts = [
            event?.location?.name,
            event?.location?.address,
            event?.location?.city,
            event?.location?.state,
            event?.location?.postalCode
        ].filter(part => part).join(' ');

        navigator.clipboard.writeText(addressParts).then(() => {
            toast.success("Address copied to clipboard!", TOAST_OPTIONS);
        }).catch(error => {
            toast.error("Failed to copy address.", TOAST_OPTIONS);
        });
    }


    return (
        <div className='flex flex-1 flex-col md:flex-row justify-between md:items-start gap-5'>
            <div className='flex flex-col gap-2 flex-[1]'>
                {(!!event.image_urls && isGuest) &&
                    <div className='flex md:hidden relative w-full rounded-xl overflow-hidden mb-2'>
                        {event.image_urls?.length > 0 &&
                            <ImageLightbox
                                images={event.image_urls}
                                className={'w-full object-cover'}
                            />}
                    </div>
                }
                <div className='flex flex-row justify-between'>
                    <div className='flex flex-col'>
                        <p className={`uppercase text-xs sm:text-sm text-primary font-medium hidden md:flex`}>
                            EVENT
                        </p>
                        <p className='text-2xl md:text-4xl font-bold'>
                            {event.title}
                        </p>
                    </div>
                </div>
                <ShowMoreText lines={1} className={'text-slate-500 mb-2 flex flex-col md:hidden'}
                              anchorClass={'text-primary'}>
                    <p className=''>
                        {event?.description}
                    </p>
                </ShowMoreText>
                <ShowMoreText lines={3} className={'text-slate-500 mb-0 hidden md:flex flex-col'}
                              anchorClass={'text-primary'}>
                    <p className=''>
                        {event?.description}
                    </p>
                </ShowMoreText>
                <div className='flex flex-row items-center gap-8 text-xs md:text-sm'>
                    <div className='flex flex-row items-center gap-0.5'>
                        <SvgCalendar className={'w-4 h-4 mr-1'}/>
                        <p className='inline-flex'>
                            {isMultiDayEvent ?
                                `${DateTime.fromISO(event.start_timestamp).toFormat("LLL d")}${dateOrdinal(DateTime.fromISO(event.start_timestamp))} ${DateTime.fromISO(event.start_timestamp).toFormat("hh:mm a")}
                              - ${DateTime.fromISO(event.end_timestamp).toFormat("LLL d")}${dateOrdinal(DateTime.fromISO(event.end_timestamp))} ${DateTime.fromISO(event.end_timestamp).toFormat("hh:mm a")}`
                                : `${DateTime.fromISO(event.start_timestamp).toFormat("LLL d")}${dateOrdinal(DateTime.fromISO(event.start_timestamp))}`
                            }
                        </p>
                    </div>
                    {!isMultiDayEvent &&
                        <div className='flex flex-row items-center gap-0.5'>
                            <SvgClock className={'w-4 h-4 mr-1'}/>
                            <p className='inline-flex'>
                                {`${DateTime.fromISO(event.start_timestamp).toFormat("hh:mm a")} - ${DateTime.fromISO(event.end_timestamp).toFormat("hh:mm a")}`}
                            </p>
                        </div>
                    }
                </div>
                {!hideLocation &&
                    <div
                        onClick={handleCopy}
                        className='flex flex-row items-center cursor-pointer hover:underline text-xs md:text-sm gap-0.5'>
                        <SvgLocation className='w-4 h-4 mt-0.5 mr-1'/>
                        <p>{event?.location?.name}</p>
                        <p>{event?.location?.address}</p>
                        <p>{event?.location?.city}, {event?.location?.state} {event?.location?.postalCode}</p>
                    </div>
                }
            </div>
            {(event.image_urls?.length > 0 && isGuest) &&
                <div className='flex-[.5]  flex-row items-center gap-2 max-w-full overflow-x-scroll hidden md:flex'>
                    {event.image_urls?.length > 0 &&
                        <ImageLightbox
                            images={event.image_urls}
                            className={'rounded-xl w-full md:w-[300px] max-h-[120px] object-cover'}
                        />}
                </div>
            }
        </div>
    )
}

export default EventPageHeaderDetails;
